@import "~react-image-gallery/styles/css/image-gallery.css";
@import url('https://fonts.googleapis.com/css?family=Montserrat');

body {
  margin: 0;
  width: 100%;
  font-family: "Montserrat", arial, helvetica, tahoma, verdana, cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

html {
  scroll-behavior: smooth;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.social-icon {
  zoom: 2;
}

.bg-image {
  height: 90vh;
  overflow: scroll !important;
}

.mask {
  overflow: scroll !important;
}

.navbar h6 {
  color: white !important;
}

.navbar p {
  cursor: pointer;
}

.navbar-brand {
  display: flex;
  justify-content: center;
  margin: 0;
}

.navbar-collapse {
  text-align: center;
  justify-content: center;
}

.nav-link {
  user-select: none;
}

.nav-link:hover {
  color: #0d6efd !important;
}

.footer-link:hover {
  color: #0d6efd !important;
}

.dropdown-item:hover {
  border-radius: 0.5rem;
}

.dropdown-toggle {
  white-space: break-spaces !important;
}

.dropdown-item {
  white-space: break-spaces !important;
}

.css-u9osun {
  color: black !important;
}

.css-h4y409-MuiList-root {
  background-color: white;
}

.css-1ontqvh {
  background-color: white;
}

.css-hyum1k-MuiToolbar-root {
  background-color: black;
}

.css-i6s8oy {
  background-color: black;
}

.email {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding-bottom: 3%;
}

.input {
  background: white;
  border: 2px solid black;
  box-sizing: border-box;
  color: black;
  display: block;
  font-size: 16pt;
  margin-bottom: 3px;
  outline: none;
  padding: 10px 15px;
  width: 100%;
  font-family: "Raleway";
  min-height: 50px;
}

.button {
  font-weight: bold;
  background: black;
  color: white;
  float: right;
  font-size: 12pt;
  margin: 5px 0 0 0;
  outline: 0;
  padding: 10px 30px;
  font-family: "Raleway";
}

.button:hover {
  font-weight: bold;
  background: white;
  color: black;
  float: right;
  font-size: 12pt;
  margin: 5px 0 0 0;
  outline: 0;
  padding: 10px 30px;
}

.nav-item {
  margin: 10px;
}

.route-content {
  position: absolute;
  top: 0;
  padding: 3%;
}

.fade:not(.show) {
  opacity: 1 !important;
}

.react-viewer-canvas {
  background: rgba(0, 0, 0, 0.8);
}

.card-description {
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
}

.src-Card-styles-module__card--1s_T3 {
  min-height: 100px !important;
}

.dropdown-toggle::after {
  color: black;
}

/* Below is a class for image carousel to display all of the content */
.css-rhsghg {
  object-fit: contain !important;
}

legend {
  float: right !important;
}

.MuiSnackbarContent-message {
  background: green !important;
}
.MuiSnackbarContent-root {
  background: green !important;
}

.sticky {
  position: sticky;
  top: 0;
  z-index: 999;
}

.email-form {
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.fab {
  color: black;
}

.fab:hover {
  color: blue;
}

/* Desktops and laptops ----------- */
@media only screen 
and (min-width : 481px) {
  .hero-banner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    padding: 2% 2%;
  }

  .input {
    min-width: 500px;
  }

  .carousel-container-custom {
    min-width: 700px;
  }

  .carousel-container {
    max-height: none !important;
  }

  .material-ui-grid {
    max-width: 100vw;
  }

  .navbar-nav {
    height: 80px;
  }
}

/* Mobile devices ----------------------------------------------*/
@media only screen 
and (min-width : 280px)
and (max-device-width : 815px) {
  .hero-banner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    padding: 5%;
  }

  .banner-subcategory {
    padding: 5%;
  }

  .carousel-container-custom {
    max-height: none !important;
    min-width: 95vw;
  }

  .material-ui-grid {
    max-width: 120vw;
  }

  .input {
    min-width: 90vw;
  }

  .col {
    padding-top: 5%;
  }
}